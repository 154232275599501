function splitProduct(sku) {
    let split = sku.lastIndexOf('_');
    let version = sku.slice(split + 1);
    let product = sku.slice(0, split);     // Remove date
    return {
        map: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
        version: version
    };
}

module.exports = splitProduct;