// function allMap(map) {
//     return Promise.all( Array.from(map, 
//         ([key, promise]) => Promise.resolve(promise).then(value => [key, value])
//     ) ).then( results => new Map(results));
// }

async function allMap(object) {
    const values = await Promise.all(Object.values(object));
    return Object.fromEntries(Object.keys(object).map((prop, i) => ([prop, values[i]])));
}

module.exports = allMap;